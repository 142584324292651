import getData from "@/__main__/get-data.mjs";
import { mergeArrayObjectsById } from "@/app/util.mjs";
import * as API from "@/game-apex/api.mjs";
import {
  DEFAULT_MATCHES_LIMIT,
  GAME_MODES,
  LIFETIME_SEASON,
} from "@/game-apex/constants.mjs";
import { APEX_MATCH_BACK_OFF } from "@/game-apex/constants/constant-network.mjs";
import type { ApexMatches } from "@/game-apex/models/matches.mjs";
import { ApexMatchesModel } from "@/game-apex/models/matches.mjs";

export default async function getMatchlist({
  blitzId,
  profileId,
  seasonId,
  season,
  mode,
  page = 0,
}): Promise<ApexMatches> {
  const params = {
    // Lol you're joking me right? its called "platformProfileId" but I am using the BlitzId instead of using ProfileId.
    // Absolute madness.
    platformProfileId: blitzId,
    seasonId,
    gameMode: mode,
    offset: page * DEFAULT_MATCHES_LIMIT,
  };
  if (season === LIFETIME_SEASON.apexSeasonNumber) delete params.seasonId;
  if (mode === GAME_MODES.ALL.key) delete params.gameMode;
  const matchlist = await getData(
    API.getMatchlist(params),
    ApexMatchesModel,
    ["apex", "matchlists", profileId, season, mode],
    {
      // This network back-off time is always in aggressive mode
      // The fetched matches themselves have their own dedicated settings to control its fetch settings and is optimized
      networkBackOffTime: APEX_MATCH_BACK_OFF,
      mergeFn: (curValue, newValue) =>
        mergeArrayObjectsById(curValue, newValue, "apexId", "gameStartedAt"),
    },
  );
  return matchlist;
}
