import type { RouteState } from "@/__main__/router.mjs";
import { getSeasons } from "@/game-apex/apex-fetch-static.mjs";
import { GAME_MODES } from "@/game-apex/constants.mjs";
import getMatches from "@/game-apex/fetches/get-matches.mjs";
import getMatchlist from "@/game-apex/fetches/get-matchlist.mjs";
import getProfile from "@/game-apex/fetches/get-profile.mjs";
import playerLegendsStats from "@/game-apex/fetches/player-legends-stats.mjs";
import playerSeasonStats from "@/game-apex/fetches/player-season-stats.mjs";
import playerWeaponStats from "@/game-apex/fetches/player-weapon-stats.mjs";
import type { Seasons } from "@/game-apex/models/seasons.mjs";
import { getCurrentSeason } from "@/game-apex/utils.mjs";
import { getParams } from "@/game-apex/utils/use-params.mjs";
import { devError, devWarn } from "@/util/dev.mjs";

export default async function fetchData(
  [profileIdRouteParam, tabRouteParam]: FixedLengthArray<string, 2>,
  searchParam: URLSearchParams,
  state: RouteState<{ page?: number; isUpdate?: boolean }>,
) {
  const profileId = profileIdRouteParam || searchParam.get("profileId");
  const tab = tabRouteParam || searchParam.get("tab");
  const profile = await getProfile(profileId, undefined, state);
  // Blitz ID is pretty much used for all of our fetch requests instead of using profileId like our other games
  // Wtf is this shit, why are we querying the BlitzId then assigning the result into a Record<profileId, data>??
  const blitzId = profile?.id;
  if (!profile || !blitzId) return;
  // Fetch seasons
  const seasons = await getSeasons({
    shouldFetchIfPathExists: false,
  });
  const { season, mode } = getParams(searchParam);
  // Local constants
  const seasonId = Object.values(seasons).find(
    (s) => s.apexSeasonNumber === season,
  )?.id;
  const promises = [];
  const isEssential = !tab || tab === "in-game";
  // Player matchlist + matches
  if (isEssential) {
    promises.push(
      getMatchlist({
        blitzId,
        profileId,
        seasonId,
        season,
        mode,
        page: state.page ?? 0,
      }),
    );
  }
  // Player legend stats
  if (isEssential || tab === "legends")
    promises.push(
      playerLegendsStats({ blitzId, profileId, seasonId, season, mode }),
    );
  // Player weapon stats
  if (isEssential || tab === "weapons")
    promises.push(
      playerWeaponStats({ blitzId, profileId, seasonId, season, mode }),
    );
  // Player season stats
  promises.push(
    getPlayerFullSeasonStats({
      blitzId,
      profileId,
      seasonId,
      season,
      mode,
    }),
  );
  // Unsure why we are fetching all seasons stats of a player.
  // Maybe this is used only for showing the rank in seasons filter?
  // This is making quite a number of requests.
  promises.push(
    getAllSeasonRankedStats({
      mode,
      blitzId,
      profileId,
      season,
      seasons,
    }),
  );
  // Transient fetching
  promises.push(
    // Transient matches
    (async () => {
      const visibleMatches = Object.entries(
        state.transient?.visibleMatches ?? {},
      )
        .filter(([_, isVisible]) => isVisible)
        // fullId represents game id + season id, which needs to be deconstructed
        .map(([fullId]) => fullId.split("|"))
        .filter((i) => i.every((j) => j)) as Array<[string, string]>;
      await getMatches(visibleMatches, true);
    })(),
    // Transient players
    (async () => {
      const visibleMatchTilePlayers = Object.entries(
        state.transient?.matchTilePlayers ?? {},
      )
        .filter(([_, isVisible]) => isVisible)
        .map(([profileId]) => profileId);
      await Promise.all(
        visibleMatchTilePlayers.map((i) => getProfile(i, undefined, state)),
      );
    })(),
  );
  try {
    await Promise.all(promises);
  } catch (e) {
    devError("Failed to fetch profile page for Apex", e);
  }
}

async function getPlayerFullSeasonStats(props: {
  blitzId: string;
  profileId: string;
  seasonId: string;
  mode: string;
  season: string;
}) {
  try {
    await playerSeasonStats(props);
    if (props.mode !== GAME_MODES.RANKED.key) {
      // recursive call this function again to fetch ranked stats
      await getPlayerFullSeasonStats({
        ...props,
        mode: GAME_MODES.RANKED.key,
      });
    }
  } catch (e) {
    if (e instanceof Error && e.message.includes("no records found")) return [];
    devError(
      `Failed to fetch Apex player season stat for profile ${props.profileId}, mode ${props.mode}`,
      e,
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getAllSeasonRankedStats({
  mode,
  profileId,
  blitzId,
  season,
  seasons,
}: {
  mode: string;
  profileId: string;
  blitzId: string;
  season: string;
  seasons: Seasons;
}) {
  const promises = [];
  const rankedMode = GAME_MODES[mode].rankedMode;
  const currentSeason = getCurrentSeason(seasons);
  // if the currently selected mode is a ranked mode, fetch all the season stats
  if (rankedMode && rankedMode === mode) {
    for (const s of Object.values(seasons)) {
      if (season === s.apexSeasonNumber) continue;
      promises.push(
        playerSeasonStats(
          {
            blitzId,
            profileId,
            seasonId: s.id,
            mode: rankedMode,
            season: s.apexSeasonNumber,
          },
          currentSeason?.apexSeasonNumber === s.apexSeasonNumber,
        ).catch((e) => {
          devWarn(
            `Failed to fetch Apex player season stat for season: ${s.id}, mode: ${rankedMode}. This is could be a false positive since we attempt to fetch all season stats for a player`,
            e,
          );
        }),
      );
    }
  }

  return Promise.all(promises);
}
